<template>
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7168 0.809129C12.8106 0.541255 13.1894 0.541254 13.2832 0.809128L16.0732 8.78152C16.1153 8.90185 16.2288 8.98242 16.3563 8.98243L25.3338 8.98274C25.6298 8.98275 25.7469 9.3659 25.5016 9.53143L18.278 14.4052C18.165 14.4814 18.1176 14.6243 18.1627 14.7529L20.9304 22.6638C21.0258 22.9363 20.7188 23.1731 20.4795 23.0116L13.1678 18.0792C13.0664 18.0108 12.9336 18.0108 12.8322 18.0792L5.52052 23.0116C5.28117 23.1731 4.97424 22.9363 5.06958 22.6638L7.83735 14.7529C7.88237 14.6243 7.83497 14.4814 7.72197 14.4052L0.498391 9.53143C0.253052 9.3659 0.370213 8.98275 0.666171 8.98274L9.64368 8.98243C9.77116 8.98242 9.88472 8.90185 9.92683 8.78152L12.7168 0.809129Z"
      :fill="isActive ? '#FFBF00' : '#CBE8F7'"
      class="progress-bar-star-svg"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
    },
  },
};
</script>
