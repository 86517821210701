<template>
  <div class="progress-bar" :progress="progress">
    <div ref="progressBarRef" class="wrapper">
      <div class="bar bar-completed" />
      <Transition name="counter">
        <div v-show="progressBar?.showCounter" class="counter">
          {{ counterString }}
        </div>
      </Transition>
      <div class="bar bar-upcoming" />
    </div>
    <SvgProgressBarStar :isActive="progress === 100" class="finish-star" />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import SvgProgressBarStar from "@/components/Svg/SvgProgressBarStar.vue";

export default {
  components: { SvgProgressBarStar },

  props: {
    // in range 0-100
    progress: Number,
  },

  setup(props) {
    const counterString = computed(() => {
      return props.progress.toFixed(0) + "%";
    });

    const progressBarRef = ref(null);

    const progressBar = computed(() => {
      if (progressBarRef.value === null) {
        return;
      }

      const progress = props.progress;
      const progressBarWidth = progressBarRef.value.offsetWidth;

      const counterWidth = 46;
      const minThreshold = 10;

      const progressInPx = (progress / 100) * progressBarWidth;
      const progressInPxWithCounter = progressInPx - counterWidth / 2;

      const completedWidthMax = progressBarWidth - counterWidth - minThreshold;
      const completedWidthMin = minThreshold;

      const completedWidth = Math.max(
        completedWidthMin,
        Math.min(progressInPxWithCounter, completedWidthMax)
      );
      const upcomingWidth = progressBarWidth - completedWidth - counterWidth;

      if (progress === 100 || progress === 0) {
        return {
          counterWidth: `${counterWidth}px`,
          counterTranslateX: `translateX(${completedWidth}px)`,
          completedWidth: `${progress}%`,
          upcomingWidth: `${100 - progress}%`,
          showCounter: false,
        };
      }

      return {
        counterWidth: `${counterWidth}px`,
        counterTranslateX: `translateX(${completedWidth}px)`,
        completedWidth: `${completedWidth}px`,
        upcomingWidth: `${upcomingWidth}px`,
        showCounter: true,
      };
    });

    return {
      counterString,
      progressBarRef,
      progressBar,
    };
  },
};
</script>

<style scoped>
.progress-bar {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 7px;
}
.progress-bar .wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.bar {
  height: 4px;
  position: absolute;
  transition: width 300ms;
  border-radius: 2px;
}
.bar-completed {
  left: 0;
  width: v-bind("progressBar.completedWidth");
  background-color: #5fb9fb;
}
.bar-upcoming {
  right: 0;
  width: v-bind("progressBar.upcomingWidth");
  background-color: #cbe8f7;
}
.progress-bar .counter {
  width: v-bind("progressBar.counterWidth");
  text-align: center;
  transform: v-bind("progressBar.counterTranslateX");
  font: 500 16px var(--default-font-family);
  color: #5fb9fb;
  transition: transform 300ms;
}
.progress-bar .counter-enter-active {
  transition: transform 300ms, opacity 300ms 150ms;
}
.progress-bar .counter-leave-active {
  transition: transform 300ms, opacity 0ms;
}
.progress-bar .counter-enter-from,
.progress-bar .counter-leave-to {
  opacity: 0;
}
.progress-bar .finish-star {
  margin-bottom: 2px;
}
.progress-bar .finish-star >>> path {
  transition: fill 300ms 100ms;
}

@media (max-width: 1200px) {
  .progress-bar {
    column-gap: 4px;
  }
}
</style>
