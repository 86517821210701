import { onBeforeUnmount, onMounted, ref } from "vue";
import { getIsMobile } from "@/helpers";

export default function useIsMobile() {
  const isMobile = ref(getIsMobile());

  const onResize = () => {
    isMobile.value = getIsMobile();
  };

  onMounted(() => {
    window.addEventListener("resize", onResize, { passive: true });
  });
  onBeforeUnmount(() => {
    window.removeEventListener("resize", onResize, { passive: true });
  });

  return { isMobile };
}
