import { computed } from "vue";
import { useRoute } from "vue-router";

export default function useProgressBar() {
  const route = useRoute();
  const progress = computed(() => {
    return route.meta.progress;
  });

  return {
    progress,
  };
}
