<template>
  <LayoutViewWrapper>
    <ProgressBar :progress="progress" class="progress-bar" />
    <router-view />
    <NavMobileBack v-if="isMobile" />
  </LayoutViewWrapper>
</template>

<script>
import { useOfferNannyStore } from "@/stores/offerNanny";
import { useRouter } from "vue-router";
import { ROUTE_OFFER_NANNY_CREATE_NAME } from "@/router/constants";
import { onUnmounted } from "vue";
import useProgressBar from "@/composables/useProgressBar";

import NavMobileBack from "@/components/NavMobileBack.vue";
import useIsMobile from "@/composables/useIsMobile";
import LayoutViewWrapper from "@/layouts/LayoutViewWrapper.vue";
import ProgressBar from "@/components/UI/ProgressBar.vue";

export default {
  components: { NavMobileBack, LayoutViewWrapper, ProgressBar },

  setup() {
    const router = useRouter();
    const { isMobile } = useIsMobile();
    const { progress } = useProgressBar();

    const initOfferDraft = async () => {
      const offerNanny = useOfferNannyStore();
      try {
        await offerNanny.initOfferDraft();
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    const offerNanny = useOfferNannyStore();
    if (offerNanny.storeMeta.isInitialized === false) {
      initOfferDraft();
      router.push({ name: ROUTE_OFFER_NANNY_CREATE_NAME });
    }

    onUnmounted(() => {
      // Reset offer if it was published
      const offerNanny = useOfferNannyStore();
      if (offerNanny.meta.status === "published") {
        offerNanny.reset();
      }
    });

    return {
      isMobile,
      progress,
    };
  },
};
</script>

<style scoped>
.progress-bar {
  margin: -20px 0 30px;
}

@media (max-width: 1200px) {
  .progress-bar {
    margin: 10px 0 30px;
  }
}
</style>
